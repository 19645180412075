import React, { useContext, useEffect, useState } from 'react'
import './whereToBuy.css';
import { PublicContext } from '../../context/public-context';
import { Paginator } from 'primereact/paginator';


import { useTranslation } from 'react-i18next';
import axios from 'axios';
export default function WhereToBuy() {


  let { baseURL,imgSrc} = useContext(PublicContext);
  const { t,i18n } = useTranslation()
  const [partners, setPartners] = useState(null);
  const [area, setArea] = useState(null)
  const [countries, setCountries] = useState(null)

  const [formData, setFormData] = useState({
    selectedArea: '0',
    selectedLocation: '0',
    selectedType: 'all'
});

const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value
    });

    if(name==="selectedArea"){
        setFormData({
          ...formData,
          [name]:value,
          selectedLocation:0
        })
        axios.get(`${baseURL}/countries/${value}`)
        .then(response =>{setCountries(response.data.data);})
        .catch(error => console.error('Error fetching partners:', error));
    }
    else{
      setFormData({
        ...formData,
        [name]: value
    });
    }
    

};

    const fetchArea = ()=> {
        axios.get(`${baseURL}/continents`)
        .then(response =>{setArea(response.data.data);})
        .catch(error => console.error('Error fetching partners:', error));
    };
    const fetchCountries = ()=> {
        axios.get(`${baseURL}/countries`)
        .then(response =>{setCountries(response.data.data);})
        .catch(error => console.error('Error fetching partners:', error));
    };
      //------------------------ for pagination -------------------------------------------
  const [pagination, setPagination] = useState({first:0,from:0,to:0,totalRecords:0,rows:12})
  useEffect(() => {
          fetchPartners(pagination.first / pagination.rows + 1);
    }, [pagination.first, pagination.rows,formData]);

  const onPageChange = (e) => {
      setPagination({
          ...pagination,
          first:e.first,
          rows:e.rows
      })
      window.scrollTo({top: 0, behavior: 'smooth'});
  };
  const fetchPartners=(page)=>{
    const params = {
        continent_id: formData.selectedArea,
        country_id: formData.selectedLocation,
        type: formData.selectedType,
        page: page
      };
    const queryParams = new URLSearchParams(params).toString();
    setPartners('');
    axios.get(`${baseURL}/partners/search?${queryParams}`)
    .then((response) => {
      const data = response.data.data;
        setPartners(response.data.data.data);
        setPagination({
          ...pagination,
          totalRecords: data.total,
          from: data.from,
          to: data.to,
          rows: data.per_page
      });
    })
    .catch((error) => { console.log('error at get collection', error); })
};

    useEffect(() => {
      fetchArea();
      fetchCountries();
    }, []);

  return (
    <div className=''>
        <div className="where-header">
            <div className="where-header-content ">
                <h3>{t('WHERE TO BUY')}</h3>
                <div className="col-10">
                  <p>{t('As a company')}</p>
                </div>
                <div className="mt-3 col-10 row g-3">
                  <div className="col-4">
                    <select className='w-100' name="selectedArea" value={formData.selectedArea} onChange={handleSelectChange}>
                      <option value="0">{ i18n.language === 'ar' ? "عالمي" : "Global"}</option>
                      {area && area.map(area=>(
                        <option value={area.id}>{ i18n.language === 'ar' ? area.name : area.name_en}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-4">
                    <select className='w-100' name='selectedLocation' value={formData.selectedLocation} onChange={handleSelectChange}>
                      <option value="0">{ i18n.language === 'ar' ? "جميع المواقع" : "All Locations"}</option>
                      {countries && countries.map(country=>(
                        <option value={country.id}>{ i18n.language === 'ar' ? country.name : country.name_en}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-4">
                    <select className='w-100' name='selectedType' value={formData.selectedType} onChange={handleSelectChange}>
                        <option value="all">{ i18n.language === 'ar' ? 'كل الأنواع' : 'All Type'}</option>
                        <option value="reseller">{ i18n.language === 'ar' ? 'موزع' : 'Reseller'}</option>

                    </select>
                  </div>
                </div>

            </div>
        </div>
        <div className="partners-container">
          <div className="partner-parent row col-10">
            {partners && partners.map(parnter=>(
                <div className=" card-container m-1 col-md-2 col-sm-2">
                  <img src={`${imgSrc}/${parnter.logo}`} alt={parnter.name} />
                  <div className="card-name">
                    <div className="card-name-hover">{ i18n.language === 'ar' ? parnter.name : parnter.name_en}</div>
                    </div>
                </div>
              ))}
          </div>
        </div>
        {partners && <div className="my-1">
                            <Paginator first={pagination.first} rows={pagination.rows} totalRecords={pagination.totalRecords} rowsPerPageOptions={[12, 24, 36]} onPageChange={onPageChange} />
                        </div>}
    </div>
  )
}
